import './style.scss';
import { Slider } from '../../../js/components/Slider/Slider';

export default !function () {
  const block = document.querySelector('.partner');

  if (!block) {
    return;
  }

  const sliderContainer = block.querySelector('.partner__slider');

  if (!sliderContainer) {
    return;
  }

  const slides = sliderContainer.querySelectorAll('.partner__item');
  const controls = sliderContainer.querySelector('.partner__controls');

  const isInitMobile = slides.length > 1;
  const isInitTablet = slides.length > 2;
  const isInitDesktop = slides.length > 3;

  const options = {
    wrapper: sliderContainer,
    settings: {
      slidesPerView: 1,
      spaceBetween: 8,

      slideClass: 'partner__item',
      wrapperClass: 'partner__list',

      pagination: {
        el: '.controls__count',
        type: 'fraction',
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 24
        },
        1024: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 32,
          loopFillGroupWithBlank: true,
          loop: true,
          navigation: {
            nextEl: '.button-arrow--next',
            prevEl: '.button-arrow--prev'
          }
        }
      }
    },
    modules: ['pagination', 'navigation', 'a11y'],
    initCondition: {
      sm: isInitMobile,
      md: isInitTablet,
      lg: isInitDesktop,
      xl: isInitDesktop
    },
    controls
  };

  new Slider(options).init();
}();
